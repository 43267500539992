import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { getUsers,userBadgePrinted,changePrintstatus } from '../redux/userSlice';
import { useEffect } from 'react';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppCurrentVisits,
  AppWidgetSummary,
  AppConversionRates,
} from '../sections/@dashboard/app';

import DatewiseChart from 'src/components/chart/DatewiseChart';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  },[]);
  const  {totalAttendess,badgePrinted,delegateCount,facultyCount,membersCount,accompanypersonCount,kitbag,TotalcertificateCount,day1Lunch,
    officeBearerCount, 
              ExecutiveCouncilCount,
              ExecutiveCouncilMemberCount,
              ExhibitorCount,
              organizingCommitteCount,
  
    day2Lunch,
    day3Lunch,
    day4Lunch,
    day5Lunch,
    day1Dinner,
    day2Dinner,
    day3Dinner,
    day4Dinner,
    day5Dinner,
    workShop1,
    workShop2,
    workShop3,
    workShop4,
    workShop5,
    workShop6,
    Certificate1,
    Certificate2,
    Certificate3,
    Certificate4,
    Certificate5,
    Certificate6,
    Certificate7,
    Certificate8,
    Certificate9,
    Certificate10,
  head_halla,
   head_hallb,
   head_hallc,
    head_halld,
    users
  }  = useSelector((state) => ({ ...state.users}));



  console.log('TotalcertificateCount',TotalcertificateCount)
  return (
    <>
      <Helmet>
        <title> Badging.com </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Attendess" total={totalAttendess > 0 ? totalAttendess : '0 Badges Printed'}  color="info" icon={'fa:users'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Badges Printed" total={badgePrinted > 0 ? badgePrinted : '0 Badges Printed'} color="info" icon={'material-symbols:badge'} />
          </Grid>

          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Certificates Issued" total={TotalcertificateCount > 0 ? TotalcertificateCount : '0 Certificates Issued'} color="warning" icon={'tdesign:certificate'} />
          </Grid> */}
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="KitBags   Delivered" total={workShop1 > 0 ? workShop1 : '0 KitBags 1 Delivered'} color="warning" icon={'tdesign:certificate'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Certificates Issued" total={day5Dinner > 0 ? day5Dinner : '0 Certificates Issued'} color="error" icon={'uim:bag'} />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AppCurrentVisits
              title="Total Badges Printed by Categorey"
              chartData={[
                { label: `Accompany Person - ${accompanypersonCount}`, value: accompanypersonCount > 0 ? accompanypersonCount : 0  },

                { label: `Delegate - ${delegateCount}`, value: delegateCount > 0 ? delegateCount : 0  },
                { label: `Executive Council - ${ExecutiveCouncilCount}`, value: ExecutiveCouncilCount > 0 ? ExecutiveCouncilCount : 0 },
                { label: `Executive Council Member - ${ExecutiveCouncilMemberCount}`, value: ExecutiveCouncilMemberCount > 0 ? ExecutiveCouncilMemberCount : 0 },
                { label: `Exhibitor - ${ExhibitorCount}`, value: ExhibitorCount > 0 ? ExhibitorCount : 0 },
                { label: `Faculty - ${facultyCount}`, value: facultyCount > 0 ? facultyCount : 0 },
                { label: `0ffice Bearer - ${officeBearerCount}`, value: officeBearerCount > 0 ? officeBearerCount : 0 },
                { label: `Organizing Committee - ${organizingCommitteCount}`, value: organizingCommitteCount > 0 ? organizingCommitteCount : 0 },
              ]}

              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
          <DatewiseChart  users={users}/>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AppConversionRates
              title="Counts"
              chartData={[

                // { label: '14th day1 breakfast', value: day3Lunch },
                { label: '23rd day1 Lunch', value: day1Dinner },
                { label: '23rd day1  Dinner', value: day2Dinner },
                { label: '24th day2 Lunch', value: day3Dinner },
                { label: '24th day2 Dinner', value: day4Dinner },
                // { label: '16th day3  breakfast', value: day1Lunch },
                // { label: '16th day3  Lunch', value: day2Lunch },
                { label: 'Certificate Scan', value: day5Dinner },
                { label: 'Kit Bag', value: workShop1 },
                // { label: 'Certificate Scan', value: workShop2 },
                // { label: 'TFO-01 hall', value: workShop3 },
                // { label: 'TFO-02 hall', value: workShop4 },
                // { label: '23rd Entry', value: day4Lunch },
                // { label: '24rd Entry', value: day5Lunch },
                // { label: '22nd Entry CME', value: workShop5 },
                // { label: '22nd Entry POCUS Advance WS', value: workShop6 },
                // { label: '22nd Entry CME', value: head_halla },
                // { label: '22nd Entry POCUS Advance WS', value: head_hallb },
                // { label: '23rd Entry', value: head_hallc },
                // { label: '24rd Entry', value: head_halld },
                // { label: 'work_shop5', value: workShop5 },
                // { label: 'work_shop6', value: workShop6 },
                // { label: 'certificate1', value: Certificate1 },
                // { label: 'certificate2', value: Certificate2 },
                // { label: 'certificate3', value: Certificate3 },
                // { label: 'certificate4', value: Certificate4 },
                // { label: 'certificate5', value: Certificate5 },
                // { label: 'certificate6', value: Certificate6 },
                // { label: 'certificate7', value: Certificate7 },
                // { label: 'certificate8', value: Certificate8 },
                // { label: 'certificate9', value: Certificate9 },
                // { label: 'certificate10', value: Certificate10 },
   
              ]}
            />
          </Grid>
          
        </Grid>
      </Container>
    </>
  );
}
